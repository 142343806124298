import moment from "moment";
import { React, Component } from "react";
import { toast } from "react-toastify";
import HeadingCompnonet from "./HeadingComponent";
import "./needHelpLists.css";
const MaterialService = require("../../../services/MaterialService");
class NeedListsHelp extends Component {
    constructor(props) {
        super(props);
    }
    state = { needListsHelp: [] };
    componentDidMount = () => {
        this.fetchData();
    };
    fetchData = () => {
        MaterialService.fetchData("/v2/manager/requests/list").then((res) => {
            if (res.status) {
                this.setState({
                    needListsHelp: res.data,
                    status: res.data.status,
                });
            } else {
                toast.error(res.message || "Something Went Wrong", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        });
    };
    onInputChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        if (value !== this.state.status) {
            this.setState({ [name]: value }, () => {
                this.updateStatus();
            });
        }
    };
    render() {
        return (
            <div id="needHelplists" className="need-help-list-main-container">
                <HeadingCompnonet
                    mainTitle="Need Help"
                    title="View request"
                    path="/material-manager/need-help-requests-list"
                    titleTwo="create need help"
                    pathTwo="/material-manager/need-help"
                />
                <div className="main-container">
                    <div className="main-need-help-heading">
                        Need help- requesting Listing Page
                    </div>
                    <div className="col-md-12 row no-padding">
                        <div className="col-md-2 no-padding row-heading">
                            Submitted On
                        </div>
                        <div className="col-md-7 no-padding row-heading">
                            Need help on
                        </div>
                        <div className="col-md-2 no-padding row-heading">
                            Status<i class="fas fa-chevron-down"></i>
                        </div>
                        <div className="col-md-1 no-padding row-heading">
                            Edit
                        </div>
                    </div>
                    <div className="col-md-12 row no-padding">
                        {(() => {
                            if (this.state.needListsHelp.length > 0) {
                                return (
                                    <>
                                        {" "}
                                        {this.state.needListsHelp.map(
                                            (lists) => {
                                                return (
                                                    <div className="col-md-12 row no-padding margin-top need-help-details-contents">
                                                        <div className="col-md-2 no-padding">
                                                            {moment(
                                                                lists.createdDate
                                                            ).format(
                                                                "DD-MMM-YYYY"
                                                            )}
                                                        </div>
                                                        <div className="col-md-7">
                                                            <div>
                                                                Title: Are you
                                                                cleaning up your
                                                                home
                                                            </div>
                                                            <img
                                                                src="/images/Rectangle.png"
                                                                className="col-md-4 no-padding need-list-image"
                                                            />
                                                        </div>
                                                        <select
                                                            className="col-md-2 select-dropdown  padding-no"
                                                            name="status"
                                                            value={lists.status}
                                                            onChange={
                                                                this
                                                                    .onInputChange
                                                            }
                                                        >
                                                            <option
                                                                value={
                                                                    "Relocating"
                                                                }
                                                            >
                                                                Relocating
                                                            </option>
                                                            <option
                                                                value={
                                                                    "SeniorCitizen"
                                                                }
                                                            >
                                                                SeniorCitizen
                                                            </option>
                                                            <option
                                                                value={
                                                                    "House Declutter"
                                                                }
                                                            >
                                                                House Declutter
                                                            </option>
                                                        </select>
                                                        <div className="col-md-1 banner-edit-button">
                                                            <i
                                                                class="fas fa-pencil-alt"
                                                                aria-hidden="true"
                                                            ></i>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        )}
                                    </>
                                );
                            } else {
                                return (
                                    <div
                                        className="alert alert-warning"
                                        style={{
                                            width: "max-content",
                                            height: "50px",
                                            textAlign: "center",
                                            margin: "auto",
                                        }}
                                    >
                                        No Data Found
                                    </div>
                                );
                            }
                        })()}
                    </div>
                </div>
            </div>
        );
    }
}

export default NeedListsHelp;
