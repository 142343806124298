import { Component } from "react";
import Validator from "validatorjs";
import {
    BsArea,
    BsInput,
    SelectComponent,
} from "../../../components/Form/FormComponents";
import history from "../../../history";
import "./dropOffSetup.css";
import HeadingCompnonet from "./HeadingComponent";
import { toast } from "react-toastify";
import LocationInput from "../../../components/Generic/LocationInput";
import SimpleMap from "../../../components/Generic/Maps/Map";
import LoaderSpinner from "../../../components/Generic/LoaderSpinner";
const MaterialService = require("../../../services/MaterialService");
class DropOffSetup extends Component {
    constructor(props) {
        super(props);
    }
    state = { loaded: false };
    save = async () => {
        await this.setState({ errors: {} });
        let rules = {
            name: "required",
            email: "required|email",
            mobile: "required|numeric|digits:10",
            availability: "required",
            locality: "required",
            localityName: "required",
            description: "required",
            selectStatus: "required",
        };
        let message = {
            "required.name": "Enter name",
            "required.email": "Enter email",
            "email.email": "Enter valid email",
            "required.mobile": "Enter mobile",
            "numeric.mobile": "Enter only number",
            "digits.mobile": "Enter valid mobile number",
            "required.availability": "Enter availability",
            "required.locality": "Enter locality",
            "required.localityName": "Enter locality name",
            "required.description": "Enter short bio",
            "required.selectStatus": "Select status",
        };
        let validator = new Validator(this.state, rules, message);
        if (validator.fails()) {
            this.setState({
                errors: {
                    ...(this.state.errors || {}),
                    ...validator.errors.all(),
                },
            });
            return;
        }
        if (validator.passes()) {
            let submit = {
                category: 3,
                mobile_no: this.state.mobile,
                name: this.state.name,
                email: this.state.email,
                locality: this.state.locality,
                availability: this.state.availability,
                description: this.state.description,
                localityName: this.state.localityName,
                status: this.state.selectStatus,
            };
            MaterialService.postData(
                "/v2/get-involved/show-interest",
                submit
            ).then((res) => {
                if (res.status) {
                    toast.success(" Successfully updated", {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    history.goBack();
                } else {
                    toast.error(res.message || "Something Went Wrong", {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            });
        }
        this.setState({ errorMessage: "", errors: null });
    };
    onInputChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        if (event.target.type === "file") {
            this.setState({ [name]: event.target.files[0] });
        } else {
            this.setState({ [name]: value });
        }
    };
    getErrorMessage = (value) => {
        if (this.state.errors && this.state.errors[value]) {
            return (
                <div className="invalid-feedback" style={{ display: "block" }}>
                    {this.state.errors[value]}
                </div>
            );
        }
    };
    update = async () => {
        await this.setState({ errors: {} });
        let rules = {
            name: "required",
            email: "required|email",
            mobile: "required|numeric|digits:10",
            availability: "required",
            locality: "required",
            localityName: "required",
            description: "required",
            selectStatus: "required",
        };
        let message = {
            "required.name": "Enter name",
            "required.email": "Enter email",
            "email.email": "Enter valid email",
            "required.mobile": "Enter mobile",
            "numeric.mobile": "Enter only number",
            "digits.mobile": "Enter valid mobile number",
            "required.availability": "Enter availability",
            "required.locality": "Enter locality",
            "required.localityName": "Enter locality name",
            "required.description": "Enter short bio",
            "required.selectStatus": "Select status",
        };
        let validator = new Validator(this.state, rules, message);
        if (validator.fails()) {
            this.setState({
                errors: {
                    ...(this.state.errors || {}),
                    ...validator.errors.all(),
                },
            });
            return;
        }
        if (validator.passes()) {
            let submit = {
                category: 3,
                mobile_no: this.state.mobile,
                name: this.state.name,
                email: this.state.email,
                locality: this.state.locality,
                availability: this.state.availability,
                description: this.state.description,
                localityName: this.state.localityName,
                status: this.state.selectStatus,
            };
            MaterialService.postData(
                `/v2/manager/drop-off/update/${this.props.match.params.id}`,
                submit
            ).then((res) => {
                if (res.status) {
                    toast.success(" Successfully updated", {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    history.goBack();
                } else {
                    toast.error(res.message || "Something Went Wrong", {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            });
        }
        this.setState({ errorMessage: "", errors: null });
    };
    componentDidMount = () => {
        if (this.props.match.params.id) {
            this.fetchData();
        } else {
            this.setState({ loaded: true });
        }
    };
    selectLocation = () => {
        const rules = {
            locality: "required",
        };
        const message = {
            "required.locality": "Enter your locality",
        };
        let validator = new Validator(this.state, rules, message);

        if (validator.fails()) {
            this.setState({
                errors: {
                    ...(this.state.errors || {}),
                    ...validator.errors.all(),
                },
            });
            this.testRef.current.scrollIntoView({ behavior: "smooth" });
            return;
        }
        if (validator.passes()) {
            this.srcollRef.current.scrollIntoView({ behavior: "smooth" });
            this.setState({
                pickUp: false,
                dropOff: true,
                selectMaterials: true,
                componentHeader: 2,
            });
        }
    };
    selectMAterialsLocation = () => {
        const rules = {
            locality: "required",
        };
        const message = {
            "required.locality": "Enter your locality",
        };
        let validator = new Validator(this.state, rules, message);

        if (validator.fails()) {
            this.setState({
                errors: {
                    ...(this.state.errors || {}),
                    ...validator.errors.all(),
                },
            });
            this.testRef.current.scrollIntoView({ behavior: "smooth" });
            return;
        }
        if (validator.passes()) {
            this.srcollRef.current.scrollIntoView({ behavior: "smooth" });
            this.setState({
                pickUp: true,
                dropOff: false,
                selectMaterials: true,
                componentHeader: 2,
            });
        }
    };
    fetchData = async () => {
        await MaterialService.fetchData(
            `/v2/manager/drop-off/detail/${this.props.match.params.id}`
        ).then((res) => {
            if (res.status) {
                this.setState({
                    details: res.data,
                    name: res.data.miscInfo.name,
                    email: res.data.miscInfo.email,
                    mobile: res.data.miscInfo.mobile_no,
                    availability: res.data.miscInfo.availability,
                    locality: res.data.locality,
                    description: res.data.miscInfo.description,
                    selectStatus: res.data.status,
                    localityName: res.data.miscInfo.localityName,
                    loaded: true,
                });
            }
        });
    };
    render() {
        if (!this.state.loaded) {
            return <LoaderSpinner />;
        }
        return (
            <div className="main-container" id="dropOffSetup">
                <HeadingCompnonet mainTitle="Drop-off Locations" />
                <div className="main-drop-off-container"></div>
                <div className="mian-drop-off-heading">
                    {this.props.match.params.id
                        ? "Edit dropoff locations"
                        : "Setup dropoff locations"}
                </div>
                <div className="col-md-12 row">
                    <div className="col-md-6">
                        <BsInput
                            required
                            label="Primary Contact Name "
                            placeholder="Enter name"
                            name="name"
                            onChange={this.onInputChange}
                            value={this.state.name}
                            errors={this.state.errors}
                        />
                    </div>
                    <div className="col-md-6">
                        <BsInput
                            required
                            label="Email"
                            placeholder="Enter mail"
                            name="email"
                            onChange={this.onInputChange}
                            value={this.state.email}
                            errors={this.state.errors}
                        />
                    </div>
                    <div className="col-md-6">
                        <BsInput
                            required
                            label="Mobile"
                            placeholder=" Enter mobile number"
                            name="mobile"
                            onChange={this.onInputChange}
                            value={this.state.mobile}
                            errors={this.state.errors}
                        />
                    </div>
                    <div className="col-md-6">
                        <BsInput
                            required
                            label="Availability"
                            placeholder="Enter the Volunteer available time"
                            name="availability"
                            onChange={this.onInputChange}
                            value={this.state.availability}
                            errors={this.state.errors}
                        />
                    </div>
                    <div className="col-md-6 ">
                        <div className="label-group">
                            <label
                                for="exampleInputorg_type"
                                style={{ width: "100%" }}
                            >
                                Enter your locality
                                <span className="required">*</span>
                            </label>
                        </div>
                        <LocationInput
                            defaultValue={this.state.locality || {}}
                            type={["address"]}
                            onLocationSelected={(locality) => {
                                if (
                                    locality.city &&
                                    locality.city.startsWith("Hyderabad")
                                ) {
                                    this.setState({
                                        locality: locality,
                                        errors: {
                                            ...this.state.errors,
                                            locality: null,
                                        },
                                    });
                                } else {
                                    this.setState({
                                        locality: null,
                                        errors: {
                                            ...this.state.errors,
                                            locality:
                                                "Sorry we dont serve to this city yet.",
                                        },
                                    });
                                }
                            }}
                            name="locality"
                            errors={this.state.errors}
                        />
                        {this.state.errors && this.state.errors.locality ? (
                            <div
                                className="alert alert-danger"
                                style={{ marginTop: "3px" }}
                            >
                                {this.state.errors.locality}
                            </div>
                        ) : null}
                    </div>
                    <div className="col-md-6">
                        <BsInput
                            required
                            label="Organization/Establishment/Apartment name"
                            placeholder="Enter the name"
                            name="localityName"
                            onChange={this.onInputChange}
                            value={this.state.localityName}
                            errors={this.state.errors}
                        />
                    </div>
                    {(this.state.locality && this.state.locality.latlong)? (
                        <div className="donate-materials-map-container">
                            <div className="donate-materials-map-text">
                                Map{" "}
                                <small>(Click to select exact location)</small>
                                <span className="highlighted-red-color">*</span>
                            </div>
                            <div className="select-map">
                                <SimpleMap
                                    onClickChange={(lat, lng) => {
                                        this.setState({
                                            locality: {
                                                ...this.state.locality,
                                                exactLocality: {
                                                    lat: lat,
                                                    lng: lng,
                                                },
                                            },
                                        });
                                    }}
                                    lat={
                                        this.state.locality
                                            ? this.state.locality.latlong
                                                  .location.lat
                                            : null
                                    }
                                    lng={
                                        this.state.locality
                                            ? this.state.locality.latlong
                                                  .location.lng
                                            : null
                                    }
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="donate-materials-map-container">
                            <div className="donate-materials-map-text">
                                Map{" "}
                                <small>(Click to select exact location)</small>
                                <span className="highlighted-red-color">*</span>
                            </div>
                            <div className="select-map">
                                <SimpleMap
                                    onClickChange={(lat, lng) => {
                                        this.setState({
                                            locality: {
                                                ...this.state.locality,
                                                exactLocality: {
                                                    lat: lat,
                                                    lng: lng,
                                                },
                                            },
                                        });
                                    }}
                                    lat={
                                        (this.state.locality && this.state.locality.latlong && this.state.locality.latlong.location)
                                            ? this.state.locality.latlong.location.lat()
                                            : null
                                    }
                                    lng={
                                        (this.state.locality && this.state.locality.latlong && this.state.locality.latlong.location)
                                            ? this.state.locality.latlong.location.lng()
                                            : null
                                    }
                                />
                            </div>
                        </div>
                    )}

                    <div className="col-md-12 no-padding">
                        <BsArea
                            required
                            label="Short Bio"
                            label2="Maximum 150 Characters"
                            value={this.state.description}
                            className="col-md-12"
                            name="description"
                            onChange={this.onInputChange}
                            errors={this.state.errors}
                            rows="4"
                            maxLength={150}
                        />
                    </div>
                    <div className="col-md-5">
                        <SelectComponent
                            required
                            name="selectStatus"
                            label="Select Status"
                            errors={this.state.errors}
                            onChange={this.onInputChange}
                            defaultValue={this.state.selectStatus}
                            className="col-md-3"
                            value={this.state.selectStatus}
                        >
                            <option value="">Select status</option>
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                        </SelectComponent>
                    </div>
                </div>
                <div className="banners-buttons">
                    {this.props.match.params.id ? (
                        <div
                            className="save-button"
                            onClick={() => {
                                this.update();
                            }}
                        >
                            Update
                        </div>
                    ) : (
                        <div
                            className="save-button"
                            onClick={() => {
                                this.save();
                            }}
                        >
                            save
                        </div>
                    )}

                    <div
                        className="cancel-button"
                        onClick={() => {
                            history.goBack();
                        }}
                    >
                        Cancel
                    </div>
                </div>
            </div>
        );
    }
}

export default DropOffSetup;
