import { Component } from "react";
import history from "../../../history";
import HeadingCompnonet from "./HeadingComponent";
import "./materialRequestsLists.css";
import LoaderSpinner from "../../../components/Generic/LoaderSpinner";
import { toast } from "react-toastify";
import  moment from "moment"
import axios from "axios";
const MaterialService = require("../../../services/MaterialService");

class MaterialsRequestLists extends Component {
    constructor(props) {
        super(props);
    }
    state = { loaded:false,materailRequestsLists: [{}, {}, {}] };

    componentDidMount(){
        this.fetchData();
    }

    fetchData = () =>{

        MaterialService.fetchData("/v2/manager/material-requests/list").then((res) => {
            if (res.status) {
                this.setState({
                    materailRequestsLists: res.data,
                    downloadKey: res.meta.downloadKey,
                    loaded:true
                });
            } else {
                toast.error(res.message || "Something Went Wrong", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        });
    }

    updateStatus = (id, status, callback = () => {}) => {
        MaterialService.postData("/v2/manager/material-requests/status/"+id, {
            id: id,
            status: status,
        }).then((res) => {
            callback();
        });
    };

    render() {
        return (
            <div className="main-container" id="materailsRequestsLists">
                <div>
                    <HeadingCompnonet mainTitle="Material Requests" />
                    <div className="banners-buttons">
                    <div className="save-button" onClick={() => {
                        window.open(`${axios.defaults.baseURL}/materials/v2/download/requests/${this.state.downloadKey}`)
                    }}>
                        download
                    </div>
                </div>
                    <div className="materails-requests-main-container">
                        <div className="col-md-12 row heading-container ">
                            <div className="col-md-1 padding-no">
                                Requested on
                            </div>
                            <div className="col-md-2 padding-no">Full Name</div>
                            <div className="col-md-2 padding-no">Email</div>
                            <div className="col-md-1 padding-no">Mobile</div>
                            <div className="col-md-3 padding-no organistaion-name">
                                ORG Name
                            </div>
                            <div className="col-md-2 padding-no">Status</div>
                            <div className="col-md-1 padding-no">Edit</div>
                        </div>
                    </div>
                    <div className="materials-requests-lists-container">
                        {
                        this.state.loaded? this.state.materailRequestsLists.map((details) => {
                            return (
                                <div className="materials-requests-lists-contents col-md-12 row" key={`materialre${details.id}`}>
                                    <div className="col-md-1 padding-no">
                                        {moment(details.createdAt).format('DD-MM-YYYY')}
                                    </div>
                                    <div className="col-md-2 padding-no">
                                        {details.miscInfo.primary_name||""}
                                    </div>
                                    <div className="col-md-2 padding-no">
                                   <a href={`mailto:${details.miscInfo.email||""}`}>{details.miscInfo.email||""}</a> 
                                    </div>
                                    <div className="col-md-1 padding-no">
                                     <a href={`tel:${details.miscInfo.mobile_no||""}`}>{details.miscInfo.mobile_no||""}</a>
                                    </div>
                                    <div className="col-md-3 padding-no">
                                    {details.miscInfo.org_name||""}
                                    </div>
                                    <select className="col-md-2 select-dropdown  padding-no" onChange={(ev)=>{
                                        this.updateStatus(details.id,ev.target.value,this.fetchData)
                                    }} value={details.status||0}>
                                        <option value={0}>Requested</option>
                                        <option value={2}>Requested Canceled</option>
                                        <option value={1}>Approved</option>
                                    </select>
                                    <div className="col-md-1 banner-edit-button  padding-no">
                                        <i class="fas fa-pencil-alt" onClick={()=>{
                                            history.push('/material-manager/material-requests-details/'+details.id)
                                        }}></i>
                                    </div>
                                </div>
                            );
                        }):<LoaderSpinner/>} 
                    </div>
                </div>
                
            </div>
        );
    }
}

export default MaterialsRequestLists;
