import React, { Component, lazy } from "react";
import { BsInput } from "../../../components/Form/FormComponents";
import history from "../../../history";
import "./materialsManagerDashboard.css";
class MaterialsManagerDashBoard extends Component {
    constructor(props) {
        super(props);
    }
    state = {
        statisticsData: [
            // { name: "Volunteer", active: 250 },
            // { name: "Logistic partners", active: 50 },
            // { name: "Supporting partners", active: 35 },
            // { name: "Gift partners", active: 36 },
            // { name: "NGO M Requests", active: 25 },
            // { name: "Pickup Requests", active: 32 },
            // { name: "Dropoff Requests", active: 45 },
        ],
    };
    render() {
        let strokeOffset = 0;
        if (70 < 10) {
            strokeOffset = 340;
        } else {
            strokeOffset = 360 * ((100 - 70) / 100);
        }
        return (
            <div
                className="materials-manager-main-container"
                id="materialsManagerDeashBoard"
            >
                <div className="materails-statistics-card-container">
                    <div className="card-container">
                        <div className="card-heading-container main-heading">
                            <div className="card-heading">Statistics</div>
                            <div className="card-heading ">Active</div>
                        </div>
                        {this.state.statisticsData.map((data) => {
                            return (
                                <div className="card-heading-container">
                                    <div className="card-heading">
                                        {data.name}
                                    </div>
                                    <div className="card-heading active-text">
                                        {data.active}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="card-container">
                        <div className="card-heading">New Requests</div>
                        <div className="card-heading-materials">
                            {this.state.statisticsData.map((data) => {
                                return (
                                    <div className="card-heading-container-design">
                                        <div className="card-heading-design">
                                            {data.name}
                                        </div>
                                        <div className="card-heading-deign active-text-design">
                                            <div>{data.active}</div>
                                            <div>Requests</div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>

                <div className="materials-statistics-container">
                    <div className="materials-statistics-container-heading">
                        Update the data from Marketing Manager Login
                    </div>
                    <div className="main-materials-progress-container">
                        <div className="material-progress-container">
                            <div className="campaign-progress">
                                <svg
                                    width="40"
                                    height="40"
                                    viewBox="0 0 120 120"
                                    style={{
                                        position: "absolute",
                                        top: "0px",
                                        left: "0px",
                                        transform: "rotate(-90deg)",
                                    }}
                                >
                                    <circle
                                        cx="60"
                                        cy="60"
                                        r="54"
                                        fill="none"
                                        stroke="#e6e6e6"
                                        strokeWidth="6"
                                    />

                                    <circle
                                        cx="60"
                                        cy="60"
                                        r="54"
                                        fill="none"
                                        stroke="url(#paint0_linear)"
                                        strokeWidth="6"
                                        strokeDasharray="339.292"
                                        strokeDashoffset={strokeOffset}
                                    />

                                    <defs>
                                        <linearGradient
                                            id="paint0_linear"
                                            x1="67.6651"
                                            y1="237.107"
                                            x2="212.132"
                                            y2="115.068"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stopColor="#F19400" />
                                            <stop
                                                offset=".45"
                                                stopColor="#E5643E"
                                            />
                                            <stop
                                                offset="1"
                                                stopColor="#DA3976"
                                            />
                                        </linearGradient>
                                    </defs>
                                </svg>
                                <img
                                    className="material-progress-image"
                                    src="/images/Vector.png"
                                />
                            </div>
                            <div className="material-progress-number">
                                4597+
                            </div>
                            <div className="material-progress-text">
                                No. of Donor
                            </div>
                        </div>
                        <div className="material-progress-container">
                            <div className="campaign-progress">
                                <svg
                                    width="40"
                                    height="40"
                                    viewBox="0 0 120 120"
                                    style={{
                                        position: "absolute",
                                        top: "0px",
                                        left: "0px",
                                        transform: "rotate(-90deg)",
                                    }}
                                >
                                    <circle
                                        cx="60"
                                        cy="60"
                                        r="54"
                                        fill="none"
                                        stroke="#e6e6e6"
                                        strokeWidth="6"
                                    />

                                    <circle
                                        cx="60"
                                        cy="60"
                                        r="54"
                                        fill="none"
                                        stroke="url(#paint0_linear)"
                                        strokeWidth="6"
                                        strokeDasharray="339.292"
                                        strokeDashoffset={strokeOffset}
                                    />

                                    <defs>
                                        <linearGradient
                                            id="paint0_linear"
                                            x1="67.6651"
                                            y1="237.107"
                                            x2="212.132"
                                            y2="115.068"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stopColor="#F19400" />
                                            <stop
                                                offset=".45"
                                                stopColor="#E5643E"
                                            />
                                            <stop
                                                offset="1"
                                                stopColor="#DA3976"
                                            />
                                        </linearGradient>
                                    </defs>
                                </svg>
                                <img
                                    className="material-progress-image"
                                    src="/images/Vector.png"
                                />
                            </div>
                            <div className="material-progress-number">
                                4597+
                            </div>
                            <div className="material-progress-text">
                                No. of Donor
                            </div>
                        </div>
                        <div className="material-progress-container">
                            <div className="campaign-progress">
                                <svg
                                    width="40"
                                    height="40"
                                    viewBox="0 0 120 120"
                                    style={{
                                        position: "absolute",
                                        top: "0px",
                                        left: "0px",
                                        transform: "rotate(-90deg)",
                                    }}
                                >
                                    <circle
                                        cx="60"
                                        cy="60"
                                        r="54"
                                        fill="none"
                                        stroke="#e6e6e6"
                                        strokeWidth="6"
                                    />

                                    <circle
                                        cx="60"
                                        cy="60"
                                        r="54"
                                        fill="none"
                                        stroke="url(#paint0_linear)"
                                        strokeWidth="6"
                                        strokeDasharray="339.292"
                                        strokeDashoffset={strokeOffset}
                                    />

                                    <defs>
                                        <linearGradient
                                            id="paint0_linear"
                                            x1="67.6651"
                                            y1="237.107"
                                            x2="212.132"
                                            y2="115.068"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stopColor="#F19400" />
                                            <stop
                                                offset=".45"
                                                stopColor="#E5643E"
                                            />
                                            <stop
                                                offset="1"
                                                stopColor="#DA3976"
                                            />
                                        </linearGradient>
                                    </defs>
                                </svg>
                                <img
                                    className="material-progress-image"
                                    src="/images/Vector.png"
                                />
                            </div>
                            <div className="material-progress-number">
                                4597+
                            </div>
                            <div className="material-progress-text">
                                No. of Donor
                            </div>
                        </div>
                        <div className="material-progress-container">
                            <div className="campaign-progress">
                                <svg
                                    width="40"
                                    height="40"
                                    viewBox="0 0 120 120"
                                    style={{
                                        position: "absolute",
                                        top: "0px",
                                        left: "0px",
                                        transform: "rotate(-90deg)",
                                    }}
                                >
                                    <circle
                                        cx="60"
                                        cy="60"
                                        r="54"
                                        fill="none"
                                        stroke="#e6e6e6"
                                        strokeWidth="6"
                                    />

                                    <circle
                                        cx="60"
                                        cy="60"
                                        r="54"
                                        fill="none"
                                        stroke="url(#paint0_linear)"
                                        strokeWidth="6"
                                        strokeDasharray="339.292"
                                        strokeDashoffset={strokeOffset}
                                    />

                                    <defs>
                                        <linearGradient
                                            id="paint0_linear"
                                            x1="67.6651"
                                            y1="237.107"
                                            x2="212.132"
                                            y2="115.068"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stopColor="#F19400" />
                                            <stop
                                                offset=".45"
                                                stopColor="#E5643E"
                                            />
                                            <stop
                                                offset="1"
                                                stopColor="#DA3976"
                                            />
                                        </linearGradient>
                                    </defs>
                                </svg>
                                <img
                                    className="material-progress-image"
                                    src="/images/Vector.png"
                                />
                            </div>
                            <div className="material-progress-number">
                                4597+
                            </div>
                            <div className="material-progress-text">
                                No. of Donor
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default MaterialsManagerDashBoard;
