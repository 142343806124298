// These must be the first lines in src/index.js
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
// import $ from "jquery";
// import Popper from "popper.js";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Provider } from "react-redux";
import axios from "axios";
import store from "./store/store";
import dotenv from "dotenv";
import dotenvExpand from "dotenv-expand";
import { registerValidators } from "./helpers/registerValidators";

registerValidators();

var myEnv = dotenv.config({ systemvars: true });
dotenvExpand(myEnv);

// let gtagid = "G-DCQ3C53EFS";

let currentenv = process.env.REACT_APP_ENV || "development";

let baseURL = process.env.REACT_APP_BASE_URL || "https://api.wishwa.org";
// let baseURL = "http://wishwa.dev.tunerlabs.in";
// // let baseURL = "https://api-test.wishwa.org";
if (currentenv === "testing") {
    baseURL = "https://api-test.wishwa.org";
}
if (currentenv === "production") {
    // gtagid = "G-K7C5GZVNWR";
    baseURL = "https://api.wishwa.org";
}

axios.defaults.baseURL = baseURL;

axios.interceptors.request.use(
    function (config) {
        // Do something before request is sent
        if (localStorage.getItem("accessToken") !== "") {
            config.headers["Authorization"] =
                "Basic " + localStorage.getItem("accessToken");
        }
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        // Do something with request error
        if (error.response && error.response.status === 403) {
            alert("Something went wrong you dont have access");
        }
        return Promise.reject(error);
    }
);

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            {" "}
            <App />{" "}
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
