import { Component } from "react";
import Validator from "validatorjs";
import {
    BsInput,
    FileUploader,
    SelectComponent,
} from "../../../components/Form/FormComponents";
import history from "../../../history";
import "./addPartner.css";
import HeadingCompnonet from "./HeadingComponent";
import { toast } from "react-toastify";
import LocationInput from "../../../components/Generic/LocationInput";
import LoaderSpinner from "../../../components/Generic/LoaderSpinner";
const MaterialService = require("../../../services/MaterialService");
class AddPartner extends Component {
    constructor(props) {
        super(props);
    }
    state = { loader: false };
    logoImage = [];
    getErrorMessage = (value) => {
        if (this.state.errors && this.state.errors[value]) {
            return (
                <div className="invalid-feedback" style={{ display: "block" }}>
                    {this.state.errors[value]}
                </div>
            );
        }
    };
    onFileChange = (event) => {
        let name = event.target.name;
        if (event.target.files[0].size > 548576) {
            this.setState({
                errors: {
                    ...this.state.errors,
                    [name]: ["File size should be less than 500KB"],
                },
            });

            return;
        } else {
            this.setState({
                [name]: event.target.files[0],
                errors: { ...this.state.errors, [name]: null },
            });
        }
    };
    onInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };
    uploadMediaFile = async (data) => {
        const file = [{ name: data.name, type: data.type, size: data.size }];
        let uploadUrls = await MaterialService.postData("/v2/get-upload-urls", {
            files: file,
        });
        if (uploadUrls.status) {
            let uploadUrl = uploadUrls.data[0];

            let uploadImage = uploadUrl.mediaObject.data;

            await Promise.all([
                new Promise((resolve, reject) => {
                    var xhr = new XMLHttpRequest();
                    xhr.open("PUT", uploadUrl.s3Url.data.postUrl, true);
                    xhr.setRequestHeader("Content-Type", uploadImage.type);
                    xhr.onload = () => {
                        if (xhr.status === 200) {
                            return resolve(uploadUrl.s3Url.data.getUrl);
                        } else {
                            reject(null);
                        }
                    };
                    xhr.send(data);
                }),
            ]);
            return uploadImage;
        }
    };
    upload = async () => {
        await this.setState({ errors: {} });
        const rules = {
            mobile: "required|numeric|digits:10",
            name: "required",
            email: "required|email",
            locality: "required",
            description: "required",
            relatioshipType: "required",
            URL: "url|required",
            status: "required",
            logoImage: "required",
        };
        if (this.state.relatioshipType == "logistics-partner") {
            rules["partner_type"] = "required";
        }
        const message = {
            "required.mobile": "Enter mobile number",
            "numeric.mobile": "Enter only numbers",
            "digits.mobile": "Enter valid mobile number",
            "required.name": "Enter name ",
            "required.email": "Enter email ",
            "email.email": "Enter valid email",
            "required.locality": "Enter address ",
            "required.logisticType": "Enter logistic Type  ",
            "required.relatioshipType": "select relatioship Type ",
            "required.description": "Enter description ",
            "required.URL": "Enter URL ",
            "url.URL": "Enter valid url",
            "required.status": "Enter status ",
            "required.logoImage": "Select logoImage ",
        };
        let validator = new Validator(this.state, rules, message);
        if (validator.fails()) {
            this.setState({
                errors: {
                    ...(this.state.errors || {}),
                    ...validator.errors.all(),
                },
            });
            return;
        }
        let image_id = await this.uploadMediaFile(this.state.logoImage);
        console.log("midiaid", image_id);
        if (validator.passes()) {
            let submit = {
                category: 3,
                mobile_no: this.state.mobile,
                org_company: this.state.org_company,
                name: this.state.name,
                email: this.state.email,
                locality: this.state.locality,
                availability: "unavailable ",
                description: this.state.description,
                wishwa_relationship: this.state.relatioshipType,
                logistic_type: this.state.partner_type,
                image_id: image_id.id,
                status: this.state.status,
                url: this.state.URL,
            };
            MaterialService.postData(
                "/v2/get-involved/show-interest",
                submit
            ).then((res) => {
                if (res.status) {
                    toast.success(" Successfully updated", {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    history.push("/material-manager/partners-list");
                } else {
                    toast.error(res.message || "Something Went Wrong", {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            });
        }
        this.setState({ errorMessage: "", errors: null });
    };
    componentWillMount = () => {
        if (this.props.match.params.id) {
            this.fetchData();
        } else {
            this.setState({ loader: true });
        }
    };
    fetchData = async () => {
        await MaterialService.fetchData(
            `/v2/manager/partner/detail/${this.props.match.params.id}`
        ).then((res) => {
            console.log("ddd");
            if (res.status) {
                this.setState({
                    details: res.data.miscInfo,
                    name: res.data.miscInfo.partnerData.name,
                    email: res.data.miscInfo.partnerData.email,
                    mobile: res.data.miscInfo.partnerData.mobile_no,
                    partner_type: res.data.miscInfo.partnerData.partner_type,
                    relatioshipType: res.data.miscInfo.partnerData.id,
                    locality: { formatted: res.data.locality.formatted },
                    description: res.data.miscInfo.partnerData.description,
                    status: res.data.status,
                    URL: res.data.miscInfo.partnerData.url,
                    org_company: res.data.miscInfo.partnerData.org_company,
                    org_company: res.data.miscInfo.partnerData.org_company,
                    logoImage: res.data.miscInfo.partnerData.image
                        ? res.data.miscInfo.partnerData.image.storageURL
                        : null,
                    loader: true,
                });
                console.log("details", this.state.details);
            }
        });
    };
    update = async () => {
        await this.setState({ errors: {} });
        const rules = {
            mobile: "required|numeric|digits:10",
            name: "required",
            email: "required|email",
            locality: "required",
            description: "required",
            relatioshipType: "required",
            URL: "url|required",
            status: "required",
            logoImage: "required",
            org_company: "required",
        };
        if (this.state.relatioshipType == "logistics-partner") {
            rules["partner_type"] = "required";
        }
        const message = {
            "required.mobile": "Enter mobile number",
            "numeric.mobile": "Enter only numbers",
            "digits.mobile": "Enter valid mobile number",
            "required.name": "Enter name ",
            "required.email": "Enter email ",
            "email.email": "Enter valid email",
            "required.locality": "Enter address ",
            "required.logisticType": "Enter logistic Type  ",
            "required.relatioshipType": "select relationship Type ",
            "required.description": "Enter description ",
            "required.org_company": "Enter Company/Org Name ",
            "required.URL": "Enter URL ",
            "url.url": "Enter valid url",
            "required.status": "Enter status ",
            "required.logoImage": "Select logoImage ",
        };
        let validator = new Validator(this.state, rules, message);
        if (validator.fails()) {
            this.setState({
                errors: {
                    ...(this.state.errors || {}),
                    ...validator.errors.all(),
                },
            });
            return;
        }
        let image_id = await this.uploadMediaFile(this.state.logoImage);
        console.log("midiaid", image_id);
        if (validator.passes()) {
            let submit = {
                category: 3,
                mobile_no: this.state.mobile,
                name: this.state.name,
                email: this.state.email,
                locality: this.state.locality,
                availability: "unavailable ",
                description: this.state.description,
                wishwa_relationship: this.state.relatioshipType,
                id: this.state.relatioshipType,
                partner_type: this.state.partner_type,
                image_id: image_id.id,
                status: this.state.status,
                org_company: this.state.org_company,
                url: this.state.URL,
            };
            MaterialService.postData(
                `/v2/manager/partner/update/${this.props.match.params.id}`,
                submit
            ).then((res) => {
                if (res.status) {
                    toast.success(" Successfully updated", {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    history.push("/material-manager/partners-list");
                } else {
                    toast.error(res.message || "Something Went Wrong", {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            });
        }
        this.setState({ errorMessage: "", errors: null });
    };
    render() {
        if (!this.state.loader) {
            return <LoaderSpinner />;
        }
        return (
            <div className="main-Container" id="addPartner">
                <HeadingCompnonet mainTitle="Our Partner" />
                <div className="main-add-partner-container">
                    {this.props.match.params.id ? (
                        <div className="main-banners-heading">Edit partner</div>
                    ) : (
                        <div className="main-banners-heading">
                            Create a new partner
                        </div>
                    )}
                    <div className="volunteer-details-input-container">
                        <div className="col-md-6 margin-top">
                            <BsInput
                                required
                                label="Name"
                                placeholder="Enter Name"
                                name="name"
                                onChange={this.onInputChange}
                                value={this.state.name}
                                errors={this.state.errors}
                            />
                        </div>

                        <div className="col-md-6 margin-top">
                            <BsInput
                                required
                                label="Email"
                                placeholder="Enter Email"
                                name="email"
                                onChange={this.onInputChange}
                                value={this.state.email}
                                errors={this.state.errors}
                            />
                        </div>
                        <div className="col-md-6 margin-top">
                            <BsInput
                                required
                                label="Mobile"
                                placeholder="Enter mobile"
                                name="mobile"
                                onChange={this.onInputChange}
                                value={this.state.mobile}
                                errors={this.state.errors}
                            />
                        </div>
                        <div className="col-md-6 margin-top">
                            <SelectComponent
                                name="relatioshipType"
                                label="Type of relationship with wishwa*"
                                errors={this.state.errors}
                                defaultValue={this.state.relatioshipType}
                                onChange={this.onInputChange}
                                value={this.state.relatioshipType}
                            >
                                <option value="">
                                    select type relationship
                                </option>
                                <option value="gift-partner">
                                    As a Gift partner
                                </option>
                                <option value={"support-partner"}>
                                    As a Supporting partner
                                </option>
                                <option value={"logistics-partner"}>
                                    As a Logistic partner
                                </option>
                                <option value={"advt-partner"}>
                                    As a Advt partner
                                </option>
                                <option value={"others"}>Others</option>
                            </SelectComponent>
                        </div>

                        <div
                            className="col-md-6 locality margin-top"
                            style={{ marginBottom: "10px" }}
                        >
                            <div className="label-group">
                                <label
                                    for="exampleInputorg_type"
                                    style={{ width: "100%" }}
                                >
                                    Enter your locality
                                    <span className="required">*</span>
                                </label>
                            </div>
                            <LocationInput
                                defaultValue={this.state.locality || {}}
                                type={["geocode"]}
                                onLocationSelected={(locality) => {
                                    this.setState({
                                        locality: locality,
                                    });
                                }}
                                name="locality"
                                errors={this.state.errors}
                            />
                            {this.getErrorMessage("locality")}
                        </div>
                        {(() => {
                            if (
                                (this.state.details &&
                                    this.state.details.partnerData &&
                                    this.state.details.partnerData.id ==
                                        "others") ||
                                this.state.relatioshipType == "others"
                            ) {
                                return (
                                    <div className="col-md-5 volunteer-input">
                                        <BsInput
                                            required
                                            label="Type of partner"
                                            placeholder="Enter type of support you can provide"
                                            name="partner_type"
                                            onChange={this.onInputChange}
                                            value={this.state.partner_type}
                                            errors={this.state.errors}
                                        />
                                    </div>
                                );
                            }
                            if (
                                (this.state.details &&
                                    this.state.details.partnerData &&
                                    this.state.details.partnerData.id ==
                                        "logistics-partner") ||
                                this.state.relatioshipType ==
                                    "logistics-partner"
                            ) {
                                return (
                                    <div className="col-md-5 volunteer-input">
                                        <BsInput
                                            required
                                            label="Logistic type"
                                            placeholder="Type of vehicle you can provide"
                                            name="partner_type"
                                            onChange={this.onInputChange}
                                            value={this.state.partner_type}
                                            errors={this.state.errors}
                                        />
                                    </div>
                                );
                            }
                        })()}
                        <div className="short-bio-container margin-top">
                            <div className="short-bio-heading-container">
                                <div className="short-bio-heading">
                                    Description
                                    <span className="required-star">*</span>
                                </div>
                                <div className="short-bio-maximum-characters">
                                    Maximum 150 Characters
                                </div>
                            </div>
                            <textarea
                                className="volunteer-bio"
                                name="description"
                                type="text"
                                errors={this.state.errors}
                                onChange={this.onInputChange}
                                value={this.state.description}
                            ></textarea>
                        </div>
                        <div className="col-md-12 volunteer-input">
                            <BsInput
                                required
                                label="Organisation / Company Name"
                                placeholder="Enter organisation name"
                                name="org_company"
                                onChange={this.onInputChange}
                                value={this.state.org_company}
                                errors={this.state.errors}
                            />
                        </div>
                        <div className="col-md-5 margin-top">
                            <FileUploader
                                required
                                section_title="Upload Partner Logo"
                                validators={{
                                    types: [".jpeg", ".png", ".tiff"],
                                }}
                                value={this.state.logoImage}
                                onChange={this.onFileChange}
                                name="logoImage"
                                title="Add Image"
                                desc=".jpeg, .png & .tiff
                        File not more than 500 KB"
                            />
                            <div className="get-error-message">
                                {this.getErrorMessage("logoImage")}
                            </div>
                        </div>
                        <div className="col-md-6 margin-top">
                            <BsInput
                                required
                                label="URL"
                                placeholder="URL"
                                name="URL"
                                type=""
                                onChange={this.onInputChange}
                                value={this.state.URL}
                                errors={this.state.errors}
                            />
                        </div>
                        <div className="col-md-3 margin-top">
                            <SelectComponent
                                name="status"
                                required
                                label="Select Status"
                                errors={this.state.errors}
                                onChange={this.onInputChange}
                                value={this.state.status}
                            >
                                <option value="">Select status</option>
                                <option value="1">Active</option>
                                <option value="2">Requested</option>
                                <option value="0">Inactive</option>
                                <option value="3">On Hold</option>
                            </SelectComponent>
                        </div>
                    </div>
                </div>
                <div className="banners-buttons">
                    {this.props.match.params.id ? (
                        <div
                            className="save-button"
                            onClick={() => {
                                this.update();
                            }}
                        >
                            Update
                        </div>
                    ) : (
                        <div
                            className="save-button"
                            onClick={() => {
                                this.upload();
                            }}
                        >
                            save
                        </div>
                    )}

                    <div
                        className="cancel-button"
                        onClick={() => {
                            history.goBack();
                        }}
                    >
                        Cancel
                    </div>
                </div>
            </div>
        );
    }
}

export default AddPartner;
