import { Component } from "react";
import Validator from "validatorjs";
import {
    BsArea,
    BsInput,
    SelectComponent,
} from "../../../components/Form/FormComponents";
import history from "../../../history";
import HeadingCompnonet from "./HeadingComponent";
import "./volunteerSetup.css";
import { toast } from "react-toastify";
import LocationInput from "../../../components/Generic/LocationInput";
import LoaderSpinner from "../../../components/Generic/LoaderSpinner";
const MaterialService = require("../../../services/MaterialService");
class VolunteerSetup extends Component {
    constructor(props) {
        super(props);
    }
    state = { loader: false };
    save = async () => {
        await this.setState({ errors: {} });
        let rules = {
            name: "required",
            email: "required|email",
            mobile: "required|numeric|digits:10",
            availability: "required",
            locality: "required",
            shortBio: "required",
            selectStatus: "required",
        };
        let message = {
            "required.name": "Enter Name",
            "required.email": "Enter email",
            "email.email": "Enter correct mail",
            "required.mobile": "Enter mobile",
            "numeric.mobile": "Enter only number",
            "digits.mobile": "Enter valid mobile number",
            "required.availability": "Enter availability",
            "required.locality": "Enter locality",
            "required.shortBio": "Enter shortBio",
            "required.selectStatus": "Select Status",
        };
        let validator = new Validator(this.state, rules, message);
        if (validator.fails()) {
            this.setState({
                errors: {
                    ...(this.state.errors || {}),
                    ...validator.errors.all(),
                },
            });
            return;
        }
        if (validator.passes()) {
            let submit = {
                category: 1,
                mobile_no: this.state.mobile,
                name: this.state.name,
                email: this.state.email,
                locality: this.state.locality,
                availability: this.state.availability,
                description: this.state.shortBio,
                status: this.state.selectStatus,
            };
            MaterialService.postData(
                "/v2/get-involved/show-interest",
                submit
            ).then((res) => {
                if (res.status) {
                    toast.success(" Successfully updated", {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    history.push("/material-manager/volunteer-lists");
                } else {
                    toast.error(res.message || "Something Went Wrong", {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            });
            history.goBack();
        }
        this.setState({ errorMessage: "", errors: null });
    };
    onInputChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        if (event.target.type === "file") {
            this.setState({ [name]: event.target.files[0] });
        } else {
            this.setState({ [name]: value });
        }
    };
    getErrorMessage = (value) => {
        if (this.state.errors && this.state.errors[value]) {
            return (
                <div className="invalid-feedback" style={{ display: "block" }}>
                    {this.state.errors[value]}
                </div>
            );
        }
    };
    fetchData = async () => {
        await MaterialService.fetchData(
            `/v2/manager/volunteer/detail/${this.props.match.params.id}`
        ).then((res) => {
            if (res.status) {
                this.setState({
                    details: res.data,
                    name: res.data.miscInfo.name,
                    email: res.data.miscInfo.email,
                    mobile: res.data.miscInfo.mobile_no,
                    availability: res.data.miscInfo.availability,
                    locality: { formatted: res.data.locality.formatted },
                    shortBio: res.data.miscInfo.description,
                    selectStatus: res.data.status,
                    loader: true,
                });
                console.log("details", this.state.details);
            }
        });
    };
    componentWillMount = () => {
        if (this.props.match.params.id) {
            this.fetchData();
        } else {
            this.setState({ loader: true });
        }
    };
    update = async () => {
        await this.setState({ errors: {} });
        let rules = {
            name: "required",
            email: "required|email",
            mobile: "required|numeric|digits:10",
            availability: "required",
            locality: "required",
            shortBio: "required",
            selectStatus: "required",
        };
        let message = {
            "required.name": "Enter Name",
            "required.email": "Enter email",
            "email.email": "Enter correct mail",
            "required.mobile": "Enter mobile",
            "numeric.mobile": "Enter only number",
            "digits.mobile": "Enter valid mobile number",
            "required.availability": "Enter availability",
            "required.locality": "Enter locality",
            "required.shortBio": "Enter shortBio",
            "required.selectStatus": "Select Status",
        };
        let validator = new Validator(this.state, rules, message);
        if (validator.fails()) {
            this.setState({
                errors: {
                    ...(this.state.errors || {}),
                    ...validator.errors.all(),
                },
            });
            return;
        }
        if (validator.passes()) {
            let submit = {
                category: 1,
                mobile_no: this.state.mobile,
                name: this.state.name,
                email: this.state.email,
                locality: this.state.locality,
                availability: this.state.availability,
                description: this.state.shortBio,
                status: this.state.selectStatus,
                id: this.props.match.params.id,
            };
            MaterialService.postData(
                `/v2/manager/volunteer/update/${this.props.match.params.id}`,
                submit
            ).then((res) => {
                if (res.status) {
                    toast.success(" Successfully updated", {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    history.push("/material-manager/volunteer-lists");
                } else {
                    toast.error(res.message || "Something Went Wrong", {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            });
            history.goBack();
        }
        this.setState({ errorMessage: "", errors: null });
    };

    render() {
        if (!this.state.loader) {
            return <LoaderSpinner />;
        }
        return (
            <div className="main-container" id="dropOffSetup">
                <HeadingCompnonet mainTitle="Volunteering Details" />
                <div className="main-volunteer-list-container">
                    <div className="mian-drop-off-heading">
                        {this.props.match.params.id
                            ? "Edit Volunteer"
                            : "Setup Volunteer"}
                    </div>
                    <div className="col-md-12 row">
                        <div className="col-md-6">
                            <BsInput
                                required
                                label="Name "
                                placeholder="Enter name"
                                name="name"
                                onChange={this.onInputChange}
                                value={this.state.name}
                                errors={this.state.errors}
                            />
                        </div>
                        <div className="col-md-6">
                            <BsInput
                                required
                                label="Email"
                                placeholder="Enter mail"
                                name="email"
                                onChange={this.onInputChange}
                                value={this.state.email}
                                errors={this.state.errors}
                            />
                        </div>
                        <div className="col-md-6">
                            <BsInput
                                required
                                label="Mobile"
                                placeholder="Enter mobile number"
                                name="mobile"
                                onChange={this.onInputChange}
                                value={this.state.mobile}
                                errors={this.state.errors}
                            />
                        </div>
                        <div className="col-md-6 availablity">
                            <BsInput
                                required
                                label="Availability"
                                placeholder="Enter the Volunteer available time"
                                name="availability"
                                onChange={this.onInputChange}
                                value={this.state.availability}
                                errors={this.state.errors}
                            />
                        </div>
                        <div className="col-md-6 ">
                            <div className="label-group">
                                <label
                                    for="exampleInputorg_type"
                                    style={{ width: "100%" }}
                                >
                                    Enter your locality
                                    <span className="required">*</span>
                                </label>
                            </div>
                            <LocationInput
                                defaultValue={this.state.locality || {}}
                                type={["geocode"]}
                                onLocationSelected={(locality) => {
                                    this.setState({
                                        locality: locality,
                                    });
                                }}
                                name="locality"
                                errors={this.state.errors}
                            />
                        </div>

                        <div className="col-md-12 no-padding margin-top">
                            <BsArea
                                required
                                label="Short Bio"
                                label2="Maximum 150 Characters"
                                value={this.state.shortBio}
                                className="col-md-12"
                                name="shortBio"
                                onChange={this.onInputChange}
                                errors={this.state.errors}
                                rows="4"
                                maxLength={150}
                            />
                        </div>
                        <div className="col-md-5">
                            <SelectComponent
                                required
                                name="selectStatus"
                                label="Select Status"
                                errors={this.state.errors}
                                onChange={this.onInputChange}
                                defaultValue={this.state.selectStatus}
                                className="col-md-3"
                                value={this.state.selectStatus}
                            >
                                <option value="-1">Select Status</option>
                                <option value="1">Active</option>
                                <option value="0">Inactive</option>
                            </SelectComponent>
                        </div>
                    </div>
                    <div className="banners-buttons">
                        {this.props.match.params.id ? (
                            <div
                                className="save-button"
                                onClick={() => {
                                    this.update();
                                }}
                            >
                                Update
                            </div>
                        ) : (
                            <div
                                className="save-button"
                                onClick={() => {
                                    this.save();
                                }}
                            >
                                save
                            </div>
                        )}

                        <div
                            className="cancel-button"
                            onClick={() => {
                                history.goBack();
                            }}
                        >
                            Cancel
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default VolunteerSetup;
