import moment from "moment";
import { Component } from "react";
import { toast } from "react-toastify";
import history from "../../../history";
import HeadingCompnonet from "./HeadingComponent";
import "./volunteerLists.css";
import axios from "axios";
const MaterialService = require("../../../services/MaterialService");
const WishwaTalkService = require("../../../services/WishwaTalkService");
class VolunteerLists extends Component {
    constructor(props) {
        super(props);
    }
    state = { volunteerLists: [] };
    componentDidMount = () => {
        this.fetchData();
    };
    componentWillMount = () => {
        if (this.props.match.params.id) {
            this.fetchData();
        }
    };
    fetchData = () => {
        MaterialService.fetchData("/v2/manager/volunteer/list").then((res) => {
            if (res.status) {
                this.setState({
                    volunteerLists: res.data,
                    status: res.data.status,
                    downloadKey: res.meta.downloadKey
                });
            } else {
                toast.error(res.message || "Something Went Wrong", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        });
    };
    onInputChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        if (value !== this.state.status) {
            this.setState({ [name]: value }, () => {
                this.updateStatus();
            });
        }
    };
    updateStatus = (id, status, callback = () => {}) => {
        MaterialService.postData("/v2/manager/volunteer/status-update", {
            id: id,
            status: status,
        }).then((res) => {
            callback();
        });
    };
    render() {
        return (
            <div className="main-container" id="volunteerLists">
                <div>
                    <HeadingCompnonet
                        mainTitle="Volunteering Details"
                        title="ADD Volunteer"
                        path="/material-manager/volunteer-setup"
                    />
                     <div className="banners-buttons">
                 
                 <div className="save-button" style={{cursor:'pointer'}} onClick={() => {
                      window.open(`${axios.defaults.baseURL}/materials/v2/download/volunteer/${this.state.downloadKey}`)
                 }}>
                     download
                 </div>
             </div>
                    <div className="main-volunteer-list-container">
                        <div className="main-volunteer-off-heading">
                            Volunteers listed
                        </div>
                        <div className="col-md-12 row heading-container ">
                            <div className="col-md-1 padding-no">Date</div>
                            <div className="col-md-1 padding-no">Full Name</div>
                            <div className="col-md-3 padding-no">Email</div>
                            <div className="col-md-2 padding-no">Mobile</div>
                            <div className="col-md-1 padding-no">
                                Availability
                            </div>
                            <div className="col-md-2 padding-no">Status</div>
                            <div className="col-md-1 padding-no">
                                Task Completed
                            </div>
                            <div className="col-md-1 padding-no">Edit</div>
                        </div>
                        <div className="drop-off-details-container">
                            {(() => {
                                if (this.state.volunteerLists.length > 0) {
                                    return (
                                        <>
                                            {" "}
                                            {this.state.volunteerLists.map(
                                                (lists) => {
                                                    return (
                                                        <div className="volunteer-details-contents col-md-12 row">
                                                            <div className="col-md-1 padding-no">
                                                                {moment(
                                                                    lists.createdDate
                                                                ).format(
                                                                    "DD-MMM-YYYY"
                                                                )}
                                                            </div>
                                                            <div className="col-md-1 padding-no">
                                                                {lists.fullName}
                                                            </div>
                                                            <div className="col-md-3 padding-no">
                                                                {lists.email}
                                                            </div>
                                                            <div className="col-md-2 padding-no">
                                                                {lists.phone}
                                                            </div>
                                                            <div className="col-md-1 padding-no">
                                                                {
                                                                    lists.availability
                                                                }
                                                            </div>
                                                            <select
                                                                className="col-md-2 select-dropdown  padding-no"
                                                                name="status"
                                                                value={
                                                                    lists.status
                                                                }
                                                                onChange={(
                                                                    event
                                                                ) => {
                                                                    this.updateStatus(
                                                                        lists.id,
                                                                        event
                                                                            .target
                                                                            .value,
                                                                        this
                                                                            .fetchData
                                                                    );
                                                                }}
                                                            >
                                                                <option value="1">
                                                                    Active
                                                                </option>
                                                                <option value="0">
                                                                    Inactive
                                                                </option>
                                                                <option value="2">
                                                                    Requested
                                                                </option>
                                                            </select>
                                                            <div
                                                                className="col-md-1"
                                                                style={{
                                                                    textAlign:
                                                                        "center",
                                                                }}
                                                            >
                                                                {
                                                                    lists.taskCompleted
                                                                }
                                                            </div>
                                                            <div className="col-md-1 banner-edit-button  padding-no">
                                                                <i
                                                                    class="fas fa-pencil-alt"
                                                                    onClick={() => {
                                                                        history.push(
                                                                            `/material-manager/volunteer-setup/${lists.id}`
                                                                        );
                                                                    }}
                                                                ></i>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </>
                                    );
                                } else {
                                    return (
                                        <div
                                            className="alert alert-warning"
                                            style={{
                                                width: "max-content",
                                                height: "50px",
                                                textAlign: "center",
                                                margin: "auto",
                                            }}
                                        >
                                            No Data Found
                                        </div>
                                    );
                                }
                            })()}
                        </div>
                    </div>
                </div>
               
            </div>
        );
    }
}

export default VolunteerLists;
