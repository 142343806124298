import ApiService from "./Axios";

const BASE_URL = "/materials";

export const fetchData = (url) => {
    let location = localStorage.getItem("location") || null;
    return ApiService.request({
        method: "GET",
        url: BASE_URL + url,
        data: {},
        headers: { location: location },
    });
};

export const postData = (url, data) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + url,
        data: data,
    });
};

export const fetchComments = (id, pageNo = 1) => {
    return fetchData(
        "/social/comments/list-comments/" + id + "?pageNo=" + pageNo
    );
};

export const postComment = (materialId, comment = "") => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + "/social/comments/post-comment",
        data: {
            materialId: materialId,
            comment: comment,
        },
    });
};
export const removeComments = (materialId, commentIds) => {
    return ApiService.request({
        method: "POST",
        url: BASE_URL + `/social/comments/remove-comments/${materialId}`,
        data: {
            commentIds: commentIds,
        },
    });
};

export const reportComment = (data) => {
    return postData("/social/comments/report-comment", data);
};

export const postMaterialDetails = (data) => {
    return postData("/give/post-material", data);
};

export const reportMaterialDetails = (data) => {
    return postData("/materials/report-material", data);
};

export const requestMaterialDetails = (data) => {
    return postData("/get/make-material-request", data);
};

export const editRequestedMaterialDetails = (data) => {
    return postData(
        "/get/make-material-request?requestId=" + data.requestId,
        data
    );
};

export const saveAsDraft = (data) => {
    return postData("/give/save-as-draft", data);
};

export const markAsGiven = (data) => {
    return postData("/give/mark-as-given", data);
};

export const rejectMaterial = (data) => {
    return postData("/backend/reviewer/reject-material", data);
};

export const approveReport = (data) => {
    return postData("/backend/reviewer/approve-report", data);
};

export const reviewerComment = (data) => {
    return postData("/backend/reviewer/add-comment", data);
};
export const postReportedChat = (data) => {
    return postData("/social/chat/report-request-chat", data);
};

export const postMaterialVideo = (data) => {
    return postData("/give/upload-video", data);
};
export const postMaterialImage = (data) => {
    return postData("/v2/upload-images", data);
};
