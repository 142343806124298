import { React, Component } from "react";
import HeadingCompnonet from "./HeadingComponent";
import "./successStoriesLists.css";
import CampaignService from "../../../services/CampaignService";
import moment from "moment";
import history from "../../../history";
const MaterialService = require("../../../services/MaterialService");
class SuccessStoriesLists extends Component {
    constructor(props) {
        super(props);
    }
    state = { successStoriesLists: [{}, {}, {}], data: [] };

    componentDidMount() {
        this.refreshData();
    }

    refreshData() {
        CampaignService.fetchData("/backend/user-stories/list?source=materials").then(
            (response) => {
                if (response.status) {
                    this.setState({
                        data: response.data,
                    });
                }
            }
        );
    }
    updateStatus = (id, status, callback = () => {}) => {
       
        CampaignService.postData("/backend/user-stories/update-story-status", {
            id: id,
            status: status,
        }).then((res) => {
            this.refreshData();
            callback();
        });
    };
    render() {
        return (
            <div
                className="success-stories-lists-main-container"
                id="succesStoriesLists"
            >
                <HeadingCompnonet
                    mainTitle="Success stories"
                    path="/material-manager/add-success-story"
                    title="Add success stories"
                />
                <div className="main-container">
                    <div className="main-success-stories-heading">
                        List of success stories
                    </div>
                    <div className="col-md-12 row no-padding">
                        <div className="col-md-2 no-padding row-heading">
                            Submitted On
                        </div>
                        <div className="col-md-7 no-padding row-heading">
                            Submitter and detail
                        </div>
                        <div className="col-md-2 no-padding row-heading">
                            Status
                        </div>
                        <div className="col-md-1 no-padding row-heading">
                            Edit
                        </div>
                    </div>
                </div>
                <div className="col-md-12 row no-padding">
                    {this.state.data.map((list) => {
                        return (
                            <div className="col-md-12 row no-padding margin-top need-lists-conainer" key={`list_id_${list.id}_${list.status}`}>
                                <div className="col-md-2 no-padding">
                                    {moment(list.createdAt).format(
                                        "DD MMM yyyy"
                                    )}
                                </div>
                                <div className="col-md-7 no-padding">
                                    <div>Title: {list.title}</div>
                                    <div className="col-md-12 no-padding image-text">
                                        <img
                                            src={list.Json.bannerImage}
                                            className="col-md-4 no-padding success-stories-list-image"
                                        />
                                        {list.shortDesc}
                                    </div>
                                </div>
                                <select
                                    className="col-md-2 select-dropdown"
                                    value={list.status}
                                    onChange={(event) => {
                                        this.updateStatus(
                                            list.id,
                                            event.target.value,
                                            this.refreshData
                                        );
                                    }}
                                >
                                    <option value="1">Visible</option>
                                    <option value="0">Hide</option>
                                </select>
                                <div className="col-md-1 banner-edit-button">
                                    <i
                                        class="fas fa-pencil-alt"
                                        aria-hidden="true"
                                        onClick={() => {
                                            history.push(
                                                `/material-manager/update-success-story/${list.id}`
                                            );
                                        }}
                                    ></i>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default SuccessStoriesLists;
